// Import PersistGate
import { store } from "./store"; // Import your Redux store and persistor
import { Provider } from "react-redux";

export default function ReduxStoreProvider({ children }) {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}>
        {() => ({ children })}
      </PersistGate> */}
      {children}
    </Provider>
  );
}
