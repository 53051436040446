import { createSlice } from "@reduxjs/toolkit";
import { setHeaderAccessToken } from "@utils/api/axiosInstance";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    user: {
      name: "guest",
      uid: null,
      authType: null,
      accessToken: null,
      role: null,
    },
    lang: "en",
    eventData: {
      eventID: null,
      viewNo: 0,
    },
    blogLoginModal: true,
  },
  reducers: {
    setUserData: (state, action) => {
      if (
        action.payload?.userData &&
        action.payload?.userData?.uid === state.user?.uid
      )
        return;

      if (action.payload?.userData?.accessToken) {
        setHeaderAccessToken(action.payload?.userData?.accessToken);
      }

      //   const newState = { user: guestUserData, lang: "en" };

      if (action.payload.userData) state.user = action.payload.userData;
      if (action.payload.lang) state.lang = action.payload.lang;

      state.blogLoginModal = false;
    },
    deleteUserData: (state) => {
      state.user = {
        name: "guest",
        uid: null,
        authType: null,
        accessToken: null,
        role: null,
      };
      state.lang = "en";
      state.eventData = {
        eventID: null,
        viewNo: 0,
      };
      state.blogLoginModal = true;
    },
    setEventData: (state, action) => {
      let update = false;
      if (action.payload?.update) {
        update = action.payload.update;
      }
      if (action.payload?.eventID === state.eventData?.eventID && !update)
        return;
      state.eventData = action.payload;
    },
    setBlogLoginModal: (state, action) => {
      state.blogLoginModal = action.payload;
    },
  },
});

export const { setUserData, setEventData, deleteUserData, setBlogLoginModal } =
  userSlice.actions;
export default userSlice.reducer;
