import { configureStore, combineReducers } from "@reduxjs/toolkit";
import subEventReducer from "./features/subEventSlice";
import registrationDetailsReducer from "./features/registrationDetailsSlice";
import cartReducer from "./features/cartSlice";
import userDetailsReducer from "./features/userSlice";
import storage from "./storage";
import { persistReducer, persistStore } from "redux-persist";
// import thunk from "redux-thunk";
import { encryptTransform } from "redux-persist-transform-encrypt";

const encryptor = encryptTransform({
  secretKey: process.env.NEXT_PUBLIC_ENCRYPTION_KEY,
  onError: function (error) {
    console.log("encryptor error", error);
    // Handle the error.
  },
});

const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptor],
  version: 1,
  // migrate: (state) => {
  //   if (state) {
  //     // Detect if the state is unencrypted and handle it
  //     if (
  //       !state._persist ||
  //       !state._persist.version ||
  //       state._persist.version < 1
  //     ) {
  //       // If old state is unencrypted, handle migration, but no need to manually encrypt
  //       const newState = {
  //         ...state, // Your state remains the same here
  //       };
  //       return Promise.resolve(newState); // redux-persist will encrypt the state
  //     }
  //   }
  //   return Promise.resolve(state);
  // },
};

const appReducer = combineReducers({
  subEventDetails: subEventReducer,
  pujaBooking: registrationDetailsReducer,
  cart: cartReducer,
  userDetails: userDetailsReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools:
    process.env.NEXT_PUBLIC_ENVIRONMENT === "DEVELOPMENT" ||
    process.env.NEXT_PUBLIC_ENVIRONMENT === "UAT",
  
  // middleware: [thunk],
});

export const persistor = persistStore(store);
