import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cartSlice",
  initialState: {
    cart: [],
    pujaAmount: 0,
    totalAmount: 0,
    totalItems: 0,
  },
  reducers: {
    addToCart: (state, action) => {
      let item = state.cart.find(
        (item) => item.subEventID === action.payload.subEventID
      );
      if (!item) {
        state.cart.push({ ...action.payload, quantity: 1 });
      } else {
        item.quantity += 1;
      }
      if (state.totalAmount === 0)
        state.totalAmount =
          state.pujaAmount + parseInt(action.payload.eventRegistrationAmount);
      else
        state.totalAmount += parseInt(action.payload.eventRegistrationAmount);
      state.totalItems += 1;
    },
    removeFromCart: (state, action) => {
      let item = state.cart.find(
        (item) => item.subEventID === action.payload.subEventID
      );
      if (item.quantity > 1) {
        item.quantity -= 1;
      } else {
        state.cart = state.cart.filter(
          (item) => item.subEventID !== action.payload.subEventID
        );
      }
      if (state.cart.length === 0) {
        state.totalAmount = 0;
        state.totalItems = 0;
      } else {
        state.totalAmount -= parseInt(action.payload.eventRegistrationAmount);
        state.totalItems -= 1;
      }
    },
    updatePujaAmount: (state, action) => {
      state.pujaAmount = action.payload;
      state.totalAmount = action.payload;
      state.totalItems = 0;
      state.cart = [];
    },
    deleteCart: (state, action) => {
      state.pujaAmount = 0;
      state.totalAmount = 0;
      state.totalItems = 0;
      state.cart = [];
    },
  },
});

export const { addToCart, removeFromCart, updatePujaAmount, deleteCart } =
  cartSlice.actions;
export default cartSlice.reducer;
