import { createSlice } from "@reduxjs/toolkit";

const subEventSlice = createSlice({
  name: "subEventSlice",
  initialState: {
    subEventDetails: null,
  },
  reducers: {
    addSubEventDetails: (state, action) => {
      state.subEventDetails = action.payload;
    },
    deleteSubEventDetails: (state, action) => {
      state.subEventDetails = null;
    },
  },
});

export const { addSubEventDetails, deleteSubEventDetails } =
  subEventSlice.actions;
export default subEventSlice.reducer;
